@import url('https://fonts.googleapis.com/css2?family=K2D:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&family=Playfair+Display&family=Playfair:opsz,wght@5..1200,300&family=Port+Lligat+Sans&family=Raleway:wght@300;400&family=Sofia+Sans+Extra+Condensed:wght@200;700&display=swap');

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.banner {
  background: linear-gradient(to right, #2c1b35, #000, #000);
}

.about {
  background: linear-gradient(#000, #22132a);

}

* {

  box-sizing: border-box;
  scroll-behavior: smooth;
  font-family: "K2D", sans-serif;
}

.banner {
 
  width: 100%;
  position: relative;
}

.banner .container {
  width: 100%;
  height: 100%;
  overflow: hidden;

}

.banner .bg-gradient {
  display: block;
  position: absolute;
  height: 100%;
  width: 30%;
  background: linear-gradient(#0000ff, #6060fe, #b80ede, #cf02fc, #c734e8);
  right: 0;
  top: 0;
  filter: blur(95px);
}

.banner .bg-img {
  position: absolute;
}


.banner .content {
  position: relative;
  height: 100%;
  width: 100%;
  z-index: 1;
  
}

.line-lh{
  font-family: "K2D", sans-serif;
  line-height: 90px;
  font-size: 60px;
}


.logo {
  font-family: "K2D", sans-serif;

}

.banner .col-2 {
  position: relative;
}

.dot {
  height: 10px;
  width: 10px;
  background-color: #cf02fc;
  border-radius: 50%;
}

.dot1 {
  height: 10px;
  width: 10px;
  background-color: #cf02fc66;
  border-radius: 50%;
}


.backglow {
  height: 800px;
}

.skill-gradient {
  display: block;
  height: 20%;
  width: 20%;
  background: linear-gradient(to right, #d001ff, #d001ff, #1414f7, #1414f7);
  rotate: 45deg;
  /* bottom: 120px; */
  position: absolute;
  filter: blur(80px);


}

 .project-gradient {
  display: block;
  height: 15%;
  width: 15%;

  background: linear-gradient(to right, #d001ff, #1414f7, #1414f7);

  bottom: 120px;
  position: absolute;
  filter: blur(90px);


}

.projects .project-gradient1 {
  display: block;
  height: 20%;
  width: 20%;

  background: linear-gradient(to right, #d001ff, #d001ff, #1414f7, #1414f7);
  top: 80px;
  position: absolute;
  filter: blur(90px);


}
.about-gradient {
  display: block;
  height: 10%;
  width: 10%;

  background: linear-gradient(to right, #3f97c8,#3f97c8 );

  bottom: 100px;
  position: absolute;
  filter: blur(60px);


}
.about-gradient1 {
  display: block;
  height: 20%;
  width: 20%;

  background: linear-gradient(to right,#1414f7, #d001ff, #d001ff, #d001ff );
  rotate: 120deg;
  bottom: 170px;
  position: absolute;
  filter: blur(100px);


}


.h-800{
  height: 800px;
}
.h-500{
  height: 500px;
}
.h-450{
  height: 450px;
}
.lowercase{
  text-transform:capitalize;
}

/* Define the styles for the scrollbar track */
::-webkit-scrollbar {
  width: 10px; /* Width of the scrollbar */
}

/* Define the styles for the scrollbar thumb (the draggable part) */
::-webkit-scrollbar-thumb {
  background: #b80ede; /* Color of the thumb */
  border-radius: 5px; /* Rounded corners */
}

/* Define the styles for the scrollbar track when the scrollbar is hovered */
::-webkit-scrollbar-thumb:hover {
  background: #15021f; /* Color of the thumb on hover */
}

/* Define the styles for the scrollbar track */
::-webkit-scrollbar-track {
  background: #4141f166; /* Color of the track */
}


